import MinusIcon from "../../../public/images/common/minus_Icon.svg";
import PlusIcon from "../../../public/images/common/plus_icon.svg";
import MinusDisableIcon from "../../../public/images/common/minus_disable_Icon.svg";
import PlusDisableIcon from "../../../public/images/common/plus_disable_icon.svg";
import IcInfo from "../../../public/images/common/ic_info_fi_g20.svg";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "next-i18next";
import { getValueByDomain } from "@modules/utils/getValuewithDomain";
export default function PeopleCard({
  idx,
  uuid,
  data,
  removeRoomHandle,
  peopleAddHandle
}) {
  const {
    t
  } = useTranslation("common");
  const addAdultCntHandler = (e, addCnt) => {
    e.stopPropagation();
    peopleAddHandle(uuid, {
      adultCnt: data.adultCnt + addCnt,
      childs: data.childs
    });
  };
  const addChildCntHandler = (e, addCnt) => {
    e.stopPropagation();
    if (addCnt > 0) {
      peopleAddHandle(uuid, {
        adultCnt: data.adultCnt,
        childs: [...data.childs, {
          cid: uuidv4(),
          age: ""
        }]
      });
    } else if (addCnt < 0) {
      peopleAddHandle(uuid, {
        adultCnt: data.adultCnt,
        childs: [...data.childs.slice(0, data.childs.length - 1)]
      });
    }
  };
  const changeChildAge = (e, cid, age) => {
    e.stopPropagation();
    let list = [...data.childs];
    list.map(info => {
      if (info.cid === cid) {
        info.age = age;
        return false;
      }
    });
    peopleAddHandle(uuid, {
      adultCnt: data.adultCnt,
      childs: list
    });
  };
  return <div className="flex flex-shrink-0 flex-grow-0 flex-col items-start justify-start self-stretch rounded-lg">
      <div className="relative flex flex-shrink-0 flex-grow-0 items-center justify-start gap-2 self-stretch py-[13px] pl-6
                   tablet:px-[16px]">
        <p className="w-[327px] text-left text-lg font-bold text-Gray-900">
          {t("pay.guest.information.room", {
          n: idx
        })}
        </p>
        {idx > 1 && <div onClick={e => {
        e.stopPropagation();
        removeRoomHandle(uuid);
      }} className="flex flex-shrink-0 flex-grow-0 items-center justify-end">
            <div className="relative flex h-[22px] flex-shrink-0 flex-grow-0 cursor-pointer items-center justify-center gap-2.5 px-1 py-[9px]">
              <p className="flex-shrink-0 flex-grow-0 text-left text-sm font-medium text-[#999]">
                {t("searchbar.btn.room.remove")}
              </p>
            </div>
          </div>}
      </div>
      <div className="relative flex flex-shrink-0 flex-grow-0 items-center gap-2 self-stretch bg-white py-1 pl-6
                   tablet:px-[16px]">
        <p className="w-[254px] text-left text-base font-medium text-Gray-900 tablet:w-[-webkit-fill-available]">
          {t("searchbar.txt.room.adult")}
        </p>
        <div className="relative flex flex-shrink-0 flex-grow-0 items-center justify-center">
          <div className="flex flex-shrink-0 flex-grow-0 cursor-pointer items-center justify-center gap-2.5 p-1" onClick={e => {
          if (data.adultCnt > 1) {
            addAdultCntHandler(e, -1);
          }
        }}>
            {data.adultCnt === 1 ? <MinusDisableIcon /> : <MinusIcon />}
          </div>
          <p className="w-[50px] flex-shrink-0 flex-grow-0 text-center text-base font-bold text-[#222]">
            {data.adultCnt}
          </p>
          <div className="flex flex-shrink-0 flex-grow-0 cursor-pointer items-center justify-center gap-2.5 p-1" onClick={e => {
          if (data.adultCnt < 8) {
            addAdultCntHandler(e, +1);
          }
        }}>
            {data.adultCnt < 8 ? <PlusIcon /> : <PlusDisableIcon />}
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 flex-col items-start justify-start self-stretch">
        <div className="relative flex flex-shrink-0 flex-grow-0 items-center justify-start gap-2 self-stretch bg-white py-1 pl-6
                     tablet:px-[16px]">
          <p className="w-[254px] text-left text-base tablet:w-[-webkit-fill-available]">
            <span className="w-[254px] flex-grow text-left text-base font-medium text-Gray-900">
              {t("searchbar.txt.room.child")}{" "}
            </span>
            <span className="w-[254px] flex-grow text-left text-base text-Gray-500">
              {t("searchbar.txt.room.child.age.under")}
            </span>
          </p>
          <div className="relative flex flex-shrink-0 flex-grow-0 items-center justify-center">
            <div className="flex flex-shrink-0 flex-grow-0 cursor-pointer items-center justify-center gap-2.5 p-1" onClick={e => {
            if (data.childs.length > 0) {
              addChildCntHandler(e, -1);
            }
          }}>
              {data.childs.length === 0 ? <MinusDisableIcon /> : <MinusIcon />}
            </div>
            <p className="w-[50px] flex-shrink-0 flex-grow-0 text-center text-base font-bold text-[#222]">
              {data.childs.length}
            </p>
            <div className="flex flex-shrink-0 flex-grow-0 cursor-pointer items-center justify-center gap-2.5 p-1" onClick={e => {
            if (data.childs.length < 8) {
              addChildCntHandler(e, +1);
            }
          }}>
              {data.childs.length < 8 ? <PlusIcon /> : <PlusDisableIcon />}
            </div>
          </div>
        </div>
        {data.childs && data.childs.length > 0 && <div className="flex flex-shrink-0 flex-grow-0 flex-col items-start justify-start gap-2 self-stretch pl-6
                       tablet:px-[16px]">
            <div className="relative flex flex-shrink-0 flex-grow-0 items-center justify-start gap-0.5 self-stretch">
              <IcInfo />
              <p className="w-[310px] flex-shrink-0 flex-grow-0 text-left text-xs text-[#999]">
                {t("searchbar.txt.room.children.age.selected")}
              </p>
            </div>
            <div className="flex flex-shrink-0 flex-grow-0 flex-wrap items-center justify-start gap-[8px] self-stretch">
              {data.childs.map((info, index) => <div key={index} className={`relative flex w-[192px] flex-shrink-0 flex-grow-0 items-center justify-center gap-2.5 overflow-hidden rounded-[8px] border bg-white px-[12px] py-[10px] ${getValueByDomain("border-Gray-50", "border-Gray-200")} 
                             tablet:w-[calc(50%_-_4px)]`} onClick={e => {
            e.stopPropagation();
          }}>
                  <select className="relative flex h-[37px] flex-grow cursor-pointer appearance-none flex-col items-start justify-center self-stretch rounded-none border-none bg-white bg-[url('/images/common/ic_arrow_down_ou_g90.svg')]
                               bg-[center_right] bg-no-repeat pt-[20px] pl-[4px]" defaultValue={info.age} onChange={e => {
              changeChildAge(e, `${info.cid}`, e.target.value);
            }}>
                    <option value="">{t("searchbar.txt.room.children.age.select")}</option>
                    {[...Array(18)].map((age, idx) => <option key={idx} value={idx}>
                        {idx === 0 ? t("searchbar.txt.room.child.age.under.select", {
                  n: idx === 0 ? 1 : idx
                }) : t("searchbar.txt.room.child.age.select", {
                  n: idx === 0 ? 1 : idx
                })}
                      </option>)}
                  </select>
                  <label className="absolute left-[16px] top-[8px] text-Gray-300 transition-all duration-[.3s] ease-[ease]">
                    {t("searchbar.txt.room.child")}
                    {index + 1}
                  </label>
                </div>)}
            </div>
          </div>}
      </div>
    </div>;
}