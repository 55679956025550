import PeopleCard from "./PeopleCard";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "next-i18next";
import RoundedSqBtn from "@components/Button/RoundedSqBtn";
import { useAtom } from "jotai/index";
import { mainPageVer2Atom } from "@store/global";
export default function RoomCard({
  roomsBoxRef,
  isRoomBoxFocus,
  defaultRoomInfo,
  roomBoxCloseHandler,
  roomSelectCompleteHandle,
  mainOpen
}) {
  const {
    t
  } = useTranslation("common");
  const [roomInfos, setRoomInfos] = useState([]);
  const [mainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기

  const roomCardRef = useRef();
  const bodyClickHandler = useCallback(event => {
    if (roomsBoxRef) {
      if (!roomsBoxRef.current.contains(event.target) && !roomCardRef.current.contains(event.target)) {
        roomSelectCompleteHandle(defaultRoomInfo);
        if (roomBoxCloseHandler) roomBoxCloseHandler();
      }
    }
  }, []);
  useEffect(() => {
    setRoomInfos(JSON.parse(JSON.stringify(defaultRoomInfo)));
    document.body.addEventListener("click", bodyClickHandler);
    return () => {
      document.body.removeEventListener("click", bodyClickHandler);
    };
  }, []);
  useEffect(() => {
    if (isRoomBoxFocus) {
      setRoomInfos(JSON.parse(JSON.stringify(defaultRoomInfo)));
    }
  }, [isRoomBoxFocus]);
  const [disabledBtn, setDisabledBtn] = useState(false);
  useEffect(() => {
    roomSelectCompleteHandle(roomInfos);
    setDisabledBtn(roomInfos?.map(room => room.people.childs?.map(child => child.age)).filter(info => info.filter(age => age === "").length > 0).length > 0);
  }, [roomInfos]);
  const addRoomHandle = () => {
    if (Object.keys(roomInfos).length < 8) {
      setRoomInfos([...roomInfos, {
        uuid: uuidv4(),
        people: {
          adultCnt: 2,
          childs: []
        }
      }]);
    }
  };
  const removeRoomHandle = uuid => {
    let data = [];
    roomInfos.map(info => {
      if (info.uuid !== uuid) {
        data.push(info);
      }
    });
    setRoomInfos(data);
  };
  const peopleAddHandle = (uuid, roomInfoData) => {
    let data = [...roomInfos];
    data.map(info => {
      if (info.uuid === uuid) {
        info.people = roomInfoData;
        return false;
      }
    });
    setRoomInfos(data);
  };
  return <>
      {!mainPageVer2 ? <div ref={roomCardRef} className={`absolute right-0 top-[72px] mt-[12px] flex flex-shrink-0 flex-grow-0 select-none flex-col items-start justify-start overflow-hidden rounded-lg bg-white pt-3.5 shadow-[0px_8px_20px_0_rgba(0,0,0,0.08)]
                  tablet:mt-0 tablet:h-[calc(100%_-_72px)] tablet:w-full tablet:pt-0 tablet:shadow-[none]
                  ${isRoomBoxFocus ? "block" : "hidden"}`} onClick={e => {
      e.stopPropagation();
    }}>
          <div className="relative flex max-h-[440px] w-[440px] flex-shrink-0 flex-grow-0 flex-col items-end justify-start overflow-y-auto
                   tablet:max-h-[calc(100%_-_80px)] tablet:w-full">
            {roomInfos?.map((info, index) => <PeopleCard key={index} idx={index + 1} uuid={info.uuid} data={info.people} removeRoomHandle={removeRoomHandle} peopleAddHandle={peopleAddHandle} />)}
          </div>

          <div className={"flex w-[440px] justify-between gap-[8px] bg-white px-6 pb-6 pt-4 tablet:absolute tablet:bottom-0 tablet:w-full tablet:p-[16px]"}>
            <RoundedSqBtn type="clear" onClick={e => {
          e.stopPropagation();
          addRoomHandle();
        }} className={"B_16_100_Bold min-w-[100px] p-[16px_18px]"} text={t("searchbar.btn.room.add")} />
            <RoundedSqBtn type="basic" onClick={() => {
          if (!disabledBtn) roomSelectCompleteHandle(roomInfos, true);
        }} disabled={disabledBtn} className={"B_16_100_Bold grow p-[16px_18px]"} text={t("searchbar.btn.rooms.add")} />
          </div>
        </div> : <div ref={roomCardRef} className={`absolute top-[72px] z-10 mt-[12px] flex select-none flex-col items-start justify-start overflow-hidden rounded-[24px] bg-white pt-3.5 shadow-[0px_8px_20px_0_rgba(0,0,0,0.08)] tablet:mt-0
                  tablet:h-[calc(100%_-_72px)] tablet:w-full tablet:pt-0 tablet:shadow-[none] mobile:left-0
                  ${isRoomBoxFocus ? "block" : "hidden"} ${mainOpen ? "left-[-200px]" : "left-0"}`} onClick={e => {
      e.stopPropagation();
    }}>
          <div className="relative flex max-h-[400px] w-[440px] flex-shrink-0 flex-grow-0 flex-col items-end justify-start overflow-y-auto
                   tablet:max-h-[calc(100%_-_80px)] tablet:w-full">
            {roomInfos?.map((info, index) => <PeopleCard key={index} idx={index + 1} uuid={info.uuid} data={info.people} removeRoomHandle={removeRoomHandle} peopleAddHandle={peopleAddHandle} />)}
          </div>

          <div className={"flex w-[440px] justify-between gap-[8px] bg-white px-6 pb-6 pt-4 tablet:absolute tablet:bottom-0 tablet:w-full tablet:p-[16px]"}>
            <RoundedSqBtn type="clear" onClick={e => {
          e.stopPropagation();
          addRoomHandle();
        }} className={"B_16_100_Bold min-w-[100px] p-[16px_18px]"} text={t("searchbar.btn.room.add")} />
            <RoundedSqBtn type="basic" onClick={() => {
          if (!disabledBtn) roomSelectCompleteHandle(roomInfos, true);
        }} disabled={disabledBtn} className={"B_16_100_Bold grow p-[16px_18px]"} text={t("searchbar.btn.rooms.add")} />
          </div>
        </div>}
    </>;
}