import { useDomain } from '@modules/hooks/useDomain';
export default function RoundedSqBtn({
  onClick,
  text = '',
  type = '',
  disabled,
  className = '',
  children,
  id
}) {
  const isTTBB = useDomain();
  const textColor = !isTTBB && 'text-white';
  const tintedText = isTTBB ? 'text-Primary600' : 'text-Primary500';
  const clearBorder = isTTBB ? 'border-Gray-200' : 'border-Gray-50';
  const clearTextColor = isTTBB ? 'text-Gray-900' : 'text-Gray-800';
  const grayColor = isTTBB ? 'bg-Gray-900' : 'bg-Gray-800';
  const btnStyle = type.includes('basic') ? `bg-Primary500 ${textColor} ${className} ${disabled && 'opacity-40'}` : type.includes('clear') ? `border border-solid ${clearBorder} ${clearTextColor} ${className}` : type.includes('nonBorder') ? `${className}` : type.includes('tinted') ? `${tintedText} bg-Primary100 ${className}` : type.includes('blackGray') ? `text-white  ${grayColor} ${className}` : ``;
  return <button id={id || 'RoundedSqBtn'} className={`flex items-center justify-center rounded-lg ${btnStyle}`} onClick={onClick} disabled={disabled || false}>
      {text}
      {children && children}
    </button>;
}